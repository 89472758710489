import '../scss/style.scss';

window.$ = window.jQuery = require('jquery');

require('./fancyapps.js');
require('./scrollbar.js');
require('./menu.js');
require('./menu-mobile.js');
require('./sticky-header.js');
require('./accordion.js');
require('./contact.js');
require('./animated');